import { mapGetters, mapActions } from 'vuex'
import dayjs from 'dayjs'
import CalendarNumber from '@/components/icons/CalendarNumber'
import ClockPrakerja from '@/components/icons/ClockPrakerja'
import Zoom from '@/components/icons/Zoom'
import Notes from '@/components/icons/Notes'
import Task from '@/components/icons/Task'
import NotFound from '@/components/illustration/404'
import { formatDate, isIOS, showVueToast, utcToLocal, getAuthHeaderJWT } from '@/utils'
import VueCookies from 'vue-cookies'
import apiV3 from '@/service/api-v3'

import isBetween from 'dayjs/plugin/isBetween'

dayjs.extend(isBetween)

const AbsensiField = () => import(/* webpackChunkName: "absensi-form" */ '@/views/Prakerja/AbsensiField')
const QuestionModal = () => import(/* webpackChunkName: "question-modal" */ '@/views/Prakerja/QuestionModal')
const VideoNotifModal = () => import(/* webpackChunkName: "VideoNotifModal" */ '@/views/Prakerja/VideoNotifModal')
const UploadTugasModal = () => import(/* webpackChunkName: "upload-tugas-modal" */ '@/views/Prakerja/UploadTugasModal')
const SubmissionFailedModal = () => import(/* webpackChunkName: "submission-failed-modal" */ '@/views/Prakerja/SubmissionFailedModal')
const UploadReflectiveJournalModal = () => import(/* webpackChunkName: "upload-reflective-journal-modal" */ '@/views/Prakerja/UploadReflectiveJournalModal')

export default {
  name: 'my-class-modal',
  filters: {
    formatDate
  },
  metaInfo() {
    return {
      title: this.$route.meta.title,
      meta: this.$route.meta.metaTags
    }
  },
  components: {
    AbsensiField,
    QuestionModal,
    UploadTugasModal,
    SubmissionFailedModal,
    UploadReflectiveJournalModal,
    CalendarNumber,
    VideoNotifModal,
    ClockPrakerja,
    Zoom,
    Notes,
    Task,
    NotFound,
    Modal: () => import(/* webpackChunkName: "modal" */ '@/components/modal/Modal'),
    RedeemPostTest: () => import(/* webpackChunkName: "redeem-post-test" */ '@/components/forms/RedeemPostTest'),
    PDFViewer2: () => import(/* webpackChunkName: "pdf-viewer" */ '@/components/pdf/PDFViewer2'),
    Chevron: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Chevron'),
    VideoPlay: () => import(/* webpackChunkName: "icon" */ '@/components/icons/VideoPlay'),
    TimeOutline: () => import(/* webpackChunkName: "icon" */ '@/components/icons/TimeOutline'),
    Attachment: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Attachment'),
    AlertCircle: () => import(/* webpackChunkName: "icon" */ '@/components/icons/AlertCircle'),
    CheckMarkCircle: () => import(/* webpackChunkName: "icons" */ '@/components/icons/CheckMarkCircle'),
    G2Academy: () => import(/* webpackChunkName: "icon" */ '@/components/icons/G2Academy'),
    Telegram: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Telegram'),
    LinkButton: () => import(/* webpackChunkName: "button" */ '@/components/button/LinkButton'),
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button'),
    ActivityCard: () => import(/* webpackChunkName: "ActivityCard" */ '@/components/prakerja/ActivityCard'),
    ChildActivityCard: () => import(/* webpackChunkName: "ChildActivityCard" */ '@/components/prakerja/ChildActivityCard'),
    InformationCard: () => import(/* webpackChunkName: "InformationCard" */ '@/components/prakerja/InformationCard'),
    VideoPlayer: () => import(/* webpackChunkName: "VideoPlayer" */ '@/components/video/VideoPlayer.vue'),
    BookLine: () => import(/* webpackChunkName: "icon" */ '@/components/icons/BookLine.vue'),
    User: () => import(/* webpackChunkName: "icon" */ '@/components/icons/User')
  },

  data() {
    return {
      visibleVideoNotifModal: false,
      toPause: false,
      dataAbsensi: {},
      visibleModalAbsensi: false,
      visibleComplainModal: false,
      visibleQuestionModal: false,
      visibleSubmissionFailedModal: false,
      submissionFailedModalMessage: '',
      visibleVideo: false,
      isTugasModalVisible: false,
      isReflectiveJournalModalVisible: false,
      tugasActivityItemId: null,
      reflectiveJournalActivityItemId: null,
      visibleModalRedeem: false,
      question: '',
      messageVideoNotif: '',
      pmoRedeemCode: '',
      examHistory: [],
      classStatus: [
        {
          progress: 0,
          value: 'Menunggu Kelas'
        },
        {
          progress: 25,
          value: 'Mengikuti Kelas'
        },
        {
          progress: 50,
          value: 'Exam dan Evaluasi'
        },
        {
          progress: 75,
          value: 'Sertifikat G2 Academy'
        },
        {
          progress: 100,
          value: 'Pelaporan ke Ecommerce'
        }
      ],
      redeemData: {
        customerName: '',
        activityId: '',
        activityItemId: '',
        targetUrl: '',
        scope: ''
      },
      classProgressStepCount: 5,
      dataIsReady: false,
      isFetching: true,
      imageUrl: '',
      instructorList: [],
      activityWithScore: ['PRETEST', 'QUIZ', 'EXAM', 'ASSIGNMENT', 'POST_TEST'],
      activityWithReference: ['QUIZ', 'EXAM', 'ASSIGNMENT'],
      activityWithRetry: ['PRETEST', 'EXAM', 'QUIZ'],
      errorWebinar: null,
      errorRecurring: null,
      certificateList: [
        {
          coreClassCertificateTitle: 'Sertifikat',
          coreClassCertificateDescription: 'Peserta akan menerima Sertifikat jika sudah menyelesaikan tahapan Unjuk Keterampilan dan sudah dinilai oleh instruktur.',
          studentCertificateNumber: '-',
          studentCertificateUrl: '',
          coreClassCertificateType: ''
        }
      ],
      isButtonActive: false,
      isScrolling: false,
      scrollTimeout: null,
      selectedActivityId: null,
      hasTheTaskCompleted: false,
      savingJurnalIsian: false
    }
  },
  computed: {
    ...mapGetters('common', ['isMobile']),
    ...mapGetters('prakerja', ['nonWebinarClassDetail', 'myClassAdditionalInfo', 'needRedemptionCode']),
    classId() {
      return this.$route.params.id
    },
    userId() {
      return localStorage.getItem('user_id')
    },
    token() {
      return VueCookies.get('token')
    },
    activities() {
      return this.nonWebinarClassDetail?.studentLmsClassActivities
    },
    requireRedemptionCode() {
      return this.nonWebinarClassDetail?.requireRedemptionCode
    },
    classProgress() {
      let totalActivities = 0
      let totalCompletedActivities = 0
      let result = 0
      for (let i = 0; i < this.activities?.length; i++) {
        for (let j = 0; j < this.activities[i].activityItems.length; j++) {
          totalActivities++
          if (typeof this.activities[i].activityItems[j].studentEndedAt !== 'undefined') {
            totalCompletedActivities++
          }
        }
      }
      result = (totalCompletedActivities / totalActivities) * 100
      return Math.round(result * 10) / 10
    },
    classType() {
      return this.$route.params.classType
    },
    activityIdFromLocalStorage() {
      return localStorage.getItem('activityId') && localStorage.getItem('activityId') !== null ? localStorage.getItem('activityId') : null
    }
  },
  created() {
    this.getDetail(this.activityIdFromLocalStorage)
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
    localStorage.removeItem('activityId')
  },
  methods: {
    ...mapActions('common', ['showLoading', 'hideLoading']),
    ...mapActions('prakerja', [
      'getNonWebinarClassDetail',
      'getMyClassAdditionalInfo',
      'updateRecurringStatus',
      'updateVideoStatus',
      'updateDocumentStatus',
      'updateLinkStatus',
      'absensiOffline',
      'absensiWebinar',
      'needRedemption'
    ]),
    handleScroll() {
      // Clear any previous scroll timeout
      clearTimeout(this.scrollTimeout)

      // Set a new scroll timeout (e.g., 500 milliseconds)
      this.scrollTimeout = setTimeout(() => {
        this.isScrolling = false // Scrolling has stopped
      }, 500) // Adjust the timeout duration as needed

      this.isScrolling = true // Scrolling is currently happening
    },
    isUploadManualTaskDisable(isRetakeable, linkUploadTask, startDate, endDate) {
      const checkTimeOver = this.isTimeOver(startDate, endDate)
      if (checkTimeOver) return checkTimeOver
      return linkUploadTask && !isRetakeable
    },
    isTimeOver(startDate, endDate) {
      if (!startDate && !endDate) return false
      const isAfterEndDate = dayjs().isAfter(utcToLocal(endDate))
      if (startDate && !endDate) return isAfterEndDate
      const isBetweenDuration = dayjs().isBetween(utcToLocal(startDate), dayjs(utcToLocal(endDate)))
      if (isBetweenDuration) return false
      return true
    },
    certificateText(type) {
      if (type === 'COMPLETION') return 'Sertifikat Penyelesaian'
      if (type === 'COMPLETION_PREDIKAT') return 'Sertifikat Penyelesaian Berpredikat'
      if (type === 'COMPETENCE') return 'Sertifikat Kompetensi'
      return 'Sertifikat Kelas'
    },
    ...mapActions('instructor', ['getInstructorById']),
    checkExercise(child) {
      return child.studentStartedAt && !child.studentEndedAt ? true : child.endTime ? dayjs(new Date()).isAfter(child.endTime) : false
    },
    onMyFrameLoad(child) {
      if (this.getStatusMessage(child).includes('text-tosca')) return
      this.triggerAction('READ', child, 'document')
      // setTimeout(() => {
      //   this.triggerAction('FINISH', child, 'document')
      // }, 1000)
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    },

    scrollToElement(activityIdFromLocalStorage) {
      if (activityIdFromLocalStorage && activityIdFromLocalStorage !== null && activityIdFromLocalStorage !== 'null') {
        const scrollTarget = this.$refs[`activity-${activityIdFromLocalStorage}`][0]
        if (scrollTarget) {
          setTimeout(() => {
            scrollTarget.scrollIntoView({ behavior: 'smooth' })
            setTimeout(() => {
              if (!this.isScrolling) {
                window.scrollBy(0, -90)
              }
            }, 1200)
          }, 1000)
        }
      }
    },

    isOpen(child) {
      if (!child.startTime) return true
      return dayjs(new Date()).isAfter(child.startTime)
    },
    isVisible(child) {
      if (child.studentEndedAt) {
        if (!child.isRetakeable) {
          return false
        } else {
          return true
        }
      } else {
        return true
      }
    },
    absensi(item, status, activityId) {
      if (this.isButtonActive) return
      this.isButtonActive = true
      let attend = () => {}
      item.activityItemType === 'WEBINAR' ? (attend = this.absensiWebinar) : (attend = this.absensiOffline)
      attend({
        studentLmsClassId: this.nonWebinarClassDetail.id,
        activityItemId: item.lmsClassActivityItemId,
        payloads: {
          actionType: status,
          tttt: '123'
        }
      }).then(() => {
        this.getDetail(activityId)
      })
    },
    getStatusMessage(item) {
      let message = ''
      if (item.isEligible) {
        if (item.studentEndedAt) {
          message = '<span class="text-tosca font-semibold">Selesai</span>'
        } else {
          message = '<span class="text-yellow font-semibold">Belum Selesai</span>'
        }
      } else {
        message = '<span class="text-neutral-400">Selesaikan aktivitas sebelumnya</span>'
      }
      return message
    },
    isFinishAction(item) {
      let status = null
      if (item.isEligible) {
        if (item.studentEndedAt) {
          status = false
        } else {
          status = true
        }
      } else {
        status = null
      }
      return status
    },
    getVideoOptions(url, videos) {
      return {
        autoplay: false,
        controls: true,
        liveui: false,
        aspectRatio: '16:9',
        controlBar: {
          liveDisplay: true,
          pictureInPictureToggle: false,
          fullscreenToggle: !isIOS
        },
        userActions: {
          doubleClick: !isIOS
        },
        poster: require('@/assets/images/prakerja/cover.jpg'),
        sources: [
          {
            src: url,
            type: 'video/mp4'
          }
        ],
        videos
      }
    },
    async saveJurnalIsian(child) {
      if (!child.reflectiveJournalAnswer1 || !child.reflectiveJournalAnswer2) {
        showVueToast('Jawaban tidak boleh kosong!', 'error', 3000)
      } else if (!this.savingJurnalIsian) {
        this.savingJurnalIsian = true
        const url = '/student/my-class/' + this.nonWebinarClassDetail?.id + '/tasks/' + child.lmsClassActivityItemId + '/reflective-journal'

        const payloads = {
          answer1: child.reflectiveJournalAnswer1,
          answer2: child.reflectiveJournalAnswer2
        }
        await apiV3.post(url, payloads, { headers: getAuthHeaderJWT() })
        await this.getDetail()
        this.savingJurnalIsian = false
      }
    },
    getDuration(totalSeconds) {
      let hours = Math.floor(totalSeconds / 3600)
      totalSeconds %= 3600
      let minutes = Math.floor(totalSeconds / 60)
      let seconds = totalSeconds % 60
      minutes = String(minutes).padStart(2, '0')
      hours = String(hours).padStart(2, '0')
      seconds = String(seconds).padStart(2, '0')
      return hours + ':' + minutes + ':' + seconds
    },
    triggerAction(action, item, type, activityId) {
      this.toPause = false
      if (type === 'document') {
        if (this.isFinishAction(item) === false) return
      }
      let updateAction = null
      if (type === 'video') {
        updateAction = this.updateVideoStatus
      } else if (type === 'document') {
        updateAction = this.updateDocumentStatus
      } else if (type === 'link') {
        updateAction = this.updateLinkStatus
      } else if (type === 'recurring') {
        updateAction = this.updateRecurringStatus
      }
      updateAction({
        studentLmsClassId: this.nonWebinarClassDetail.id,
        activityItemId: item.lmsClassActivityItemId,
        payloads: {
          actionType: action
        }
      }).then((response) => {
        if (action === 'FINISH') {
          if (!activityId) this.scrollToTop()
          this.getDetail(activityId)
        } else if (action === 'PLAY' && response.code === 400) {
          this.toPause = true
          this.messageVideoNotif = response.errors.error
          this.visibleVideoNotifModal = true
        }
      })
    },
    pauseVideo() {
      const videoElement = document.getElementById('videoPlayerId')
      if (videoElement) {
        videoElement.pause()
      }
    },
    closeModalVideoNotif() {
      this.visibleVideoNotifModal = false
    },
    showTugasModal(activityItemId, activityId, hasTheTaskCompleted) {
      this.tugasActivityItemId = activityItemId
      this.selectedActivityId = activityId
      this.isTugasModalVisible = true
      this.hasTheTaskCompleted = hasTheTaskCompleted
    },
    showSubmissionFailedModal() {
      this.visibleSubmissionFailedModal = true
    },
    closeSubmissionFailedModal() {
      this.visibleSubmissionFailedModal = false
    },
    toggleSubmissionFailedModal(show, errorMessage) {
      this.visibleSubmissionFailedModal = show
      this.submissionFailedModalMessage = errorMessage
    },
    closeTugasModal() {
      this.isTugasModalVisible = false
    },
    showReflectiveJournalModal(activityItemId, activityId, hasTheTaskCompleted) {
      this.reflectiveJournalActivityItemId = activityItemId
      this.selectedActivityId = activityId
      this.isReflectiveJournalModalVisible = true
      this.hasTheTaskCompleted = hasTheTaskCompleted
    },
    closeReflectiveJournalModal() {
      this.isReflectiveJournalModalVisible = false
    },
    handleMutateQuestion(value) {
      this.question = value
    },
    closeAndRefetch() {
      this.closeTugasModal()
      this.closeReflectiveJournalModal()
      if (!this.hasTheTaskCompleted) {
        this.getDetail(this.selectedActivityId)
      }
    },
    sendQuestion() {
      if (this.question === '') {
        showVueToast('Pertanyaan tidak boleh kosong!', 'error', 3000)
        return
      }
      this.showLoading()
      this.sendDropboxQuestion({
        id: this.classId,
        payloads: {
          question: this.question
        }
      })
        .then(() => {
          showVueToast('Pertanyaan berhasil dikirim!', 'success', 3000)
          this.hideLoading()
        })
        .catch(() => {
          showVueToast('Pertanyaan gagal dikirim!', 'error', 3000)
          this.hideLoading()
        })
      this.question = ''
      this.toggleQuestionModal()
    },
    getDocumentUrl(url) {
      return url + '?token=' + this.token
    },
    getAbsenStatus(item) {
      if (this.myClassDetail.customer_id === 1 && item.video_url) {
        return 'Sudah Menonton'
      } else if (this.myClassDetail.customer_id === 1 && item.materi) {
        return 'Sudah Membaca'
      } else {
        return 'Absen Masuk'
      }
    },
    validityCheck(index) {
      if (index < 1) {
        return this.myClassDetail.pretest_status
      } else {
        return this.myClassDetail.schedule[index - 1].quiz_value >= 80
      }
    },
    getProgress(step) {
      return (step / this.classProgressStepCount) * 100
    },
    async getDetail(activityIdFromLocalStorage) {
      if (activityIdFromLocalStorage) localStorage.setItem('activityId', activityIdFromLocalStorage)
      this.showLoading()
      this.isFetching = true
      await this.getMyClassAdditionalInfo({ classId: this.classId }).then((res) => {
        const certificates = JSON.parse(JSON.stringify(res.certificates))
        const certificatesGenerated = certificates.filter((certificate) => certificate.studentCertificateUrl)
        if (certificatesGenerated.length) {
          this.certificateList = certificatesGenerated
        }
        this.instructorList = []
        this.imageUrl = res.customer_logo_url ? res.customer_logo_url : ''
        const instructorId = res.java_instructur_id_array
        if (instructorId.length > 0) {
          for (let i = 0; i < instructorId.length; i++) {
            this.getInstructor(instructorId[i])
          }
        }
      })
      await this.getNonWebinarClassDetail({
        classId: this.classId
      })
        .then((response) => {
          if (response?.pmoRedeemCode?.length > 0) {
            this.pmoRedeemCode = response.pmoRedeemCode
          }
          this.dataIsReady = true
          this.isFetching = false
          this.isButtonActive = false
          this.hideLoading()
          if (activityIdFromLocalStorage) this.scrollToElement(activityIdFromLocalStorage)
        })
        .catch(() => {
          this.dataIsReady = false
          this.isFetching = false
          this.isButtonActive = false
          this.hideLoading()
        })
    },
    getInstructor(id) {
      this.getInstructorById({
        id: id
      })
        .then((res) => {
          this.dataIsReady = true
          this.isFetching = false
          this.hideLoading()
          if (res) this.instructorList.push(res)
        })
        .catch(() => {
          this.dataIsReady = true
          this.isFetching = false
          this.hideLoading()
        })
    },
    getExam() {
      this.getExamHistory({
        classId: this.classId,
        success: (res) => (this.examHistory = res)
      })
    },
    close() {
      this.$emit('close')
    },
    getDate(start, end) {
      const formattedStart = this.$options.filters.formatDate(start)
      const formattedEnd = this.$options.filters.formatDate(end)
      if (formattedStart === formattedEnd) {
        return formattedStart
      } else {
        return formattedStart + ' - ' + formattedEnd
      }
    },

    getHour(start, end) {
      const startTime = dayjs(String(start)).format('HH:mm')
      const endTime = dayjs(String(end)).format('HH:mm')
      return `${startTime} - ${endTime} (Waktu lokal anda)`
    },
    isValidToStartQuiz(scheduleEndTime) {
      const time = dayjs.duration('00:10:00')
      const endTime = dayjs(scheduleEndTime)
      endTime.subtract(time)
      const currentTime = dayjs(new Date())
      return dayjs(currentTime).valueOf() >= dayjs(endTime).valueOf()
    },
    getTime(input) {
      const date = this.$options.filters.formatDate(input)
      const time = dayjs(input).format('HH:mm')
      return date + ' ' + time
    },
    checkDate(start) {
      const startInMs = new Date(start).getTime()
      const current = new Date().getTime()
      return current < startInMs
    },
    successAbsen() {
      this.getDetail()
      this.closeModalAbsensi()
    },
    openAbsensi(value, state, classId) {
      this.dataAbsensi = {
        data: value,
        state: state,
        classId: classId
      }
      this.visibleModalAbsensi = true
    },
    closeModalAbsensi() {
      this.visibleModalAbsensi = false
    },
    checkField(input) {
      return input || '-'
    },
    isScheduleExist(data) {
      return data && data.length > 0
    },
    toggleComplainModal() {
      this.visibleComplainModal = !this.visibleComplainModal
    },
    toggleQuestionModal() {
      this.visibleQuestionModal = !this.visibleQuestionModal
    },
    toggleVideoNotifModal() {
      this.visibleVideoNotifModal = !this.visibleVideoNotifModal
    },
    goTo(url) {
      this.$router.push(url)
    },
    goToNewTab(url) {
      window.open(url)
    },
    getComplainHistoryList() {
      this.getComplainHistory({
        classId: this.classId
      })
    },
    async openLink(child, activityId) {
      window.open(child.link)
      // handle refetch data only when the user click first time
      if (!child.studentEndedAt) {
        const promise = new Promise((resolve, reject) => {
          this.triggerAction('OPEN', child, 'link', activityId)
          resolve()
        })
        const result = await promise
        // this.getDetail(activityId)
      }
    },
    openZoomLink(child, link) {
      this.showLoading()
      const self = this
      if (child.activityItemType === 'RECURRING_ONLINE') {
        this.updateRecurringStatus({
          studentLmsClassId: this.nonWebinarClassDetail.id,
          activityItemId: child.lmsClassActivityItemId,
          payloads: {
            actionType: 'START'
          }
        })
          .then(() => {
            this.hideLoading()
            window.open(link)
            self.updateRecurringStatus({
              studentLmsClassId: self.nonWebinarClassDetail.id,
              activityItemId: child.lmsClassActivityItemId,
              payloads: {
                actionType: 'END'
              }
            })
          })
          .catch((err) => {
            this.hideLoading()
            this.errorRecurring = err.response.data.errors.error
          })
      } else {
        if (dayjs(new Date()).isBefore(child.startTime)) {
          this.errorWebinar = 'Invalid Sign In time, must be between allocated time'
        } else if (dayjs(new Date()).isAfter(child.endTime) && this.nonWebinarClassDetail.isDummy !== true) {
          this.errorWebinar = 'Link is expired'
        } else {
          window.open(link)
        }
        this.hideLoading()
      }
    },
    getDayOfWeek(index) {
      const dayOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
      return dayOfWeek[index - 1]
    },
    getDurationTime(start, end) {
      start = start.split(':')
      end = end.split(':')
      var startDate = new Date(0, 0, 0, start[0], start[1], 0)
      var endDate = new Date(0, 0, 0, end[0], end[1], 0)
      var diff = endDate.getTime() - startDate.getTime()
      return Math.ceil(Math.abs(diff) / (1000 * 60))
    },
    removeSecond(time) {
      return time.split(':').slice(0, -1).join(':')
    },
    // isCertificateEligible(type) {
    //   if (type === 'participation') {
    //     if (this.myClassDetail.exam_value >= 60) {
    //       return true
    //     } else {
    //       if (this.myClassDetail.exam_value > this.myClassDetail.pretest_value) {
    //         return true
    //       } else {
    //         return false
    //       }
    //     }
    //   } else if (type === 'competency') {
    //     return this.myClassDetail.exam_value >= 60 && this.myClassDetail.tugas_status
    //   }
    // },
    isNotEligible(date) {
      const convertedTime = new Date(date).getTime()
      const now = new Date().getTime()
      return now < convertedTime
    },
    async openModalRedeemPostTest(activity, child) {
      this.showLoading()
      const url = `/student/class/${this.nonWebinarClassDetail.id}/exercise/${child.lmsClassActivityItemId}?activityId=${activity.lmsClassActivityId}`

      // check if need redemption code or not
      await this.needRedemption({
        classId: this.classId,
        activityItemId: child.lmsClassActivityItemId
      }).then((response) => {
        if (response.need_redemption_code) {
          this.hideLoading()
          this.redeemData.customerName = this.nonWebinarClassDetail.customerName
          this.redeemData.activityId = activity.lmsClassActivityId
          this.redeemData.activityItemId = child.lmsClassActivityItemId
          this.redeemData.targetUrl = url
          this.redeemData.scope = 'posttest'

          this.visibleModalRedeem = true
        } else {
          this.$router.push(url)
        }
      })
    },
    showModalRedeemSession(activity) {
      const url = `/student/class/${this.nonWebinarClassDetail.id}/exercise/${this.classType}`
      this.redeemData.customerName = this.nonWebinarClassDetail.customerName
      this.redeemData.activityId = activity.lmsClassActivityId
      this.redeemData.activityItemId = 0
      this.redeemData.targetUrl = url
      this.redeemData.scope = activity.isPostTest ? 'posttest' : 'session'
      this.visibleModalRedeem = !this.visibleModalRedeem
    },
    closeModalRedeem() {
      this.visibleModalRedeem = !this.visibleModalRedeem
    }
  }
}
